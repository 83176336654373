<template>
  <b-card>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form @submit.prevent="handleSubmit(onSubmit)">

        <b-row>

          <b-col cols="12" md="12" lg="12">
            <div class="d-flex">
              <feather-icon icon="PocketIcon" size="16"/>
              <h5 class="mb-0 mt-0 ml-50">Genel Bilgiler</h5>
            </div>

            <b-row class="mt-1 mb-1">

              <b-col cols="12" md="6" lg="6">
                <validation-provider #default="validationContext" name="Kod" rules="required">
                  <b-form-group label="* Kod">
                    <b-form-input trim placeholder="Kod" v-model="dataInfo.code" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12" md="6" lg="6">
                <validation-provider #default="validationContext" name="İsim" rules="required">
                  <b-form-group label="* İsim">
                    <b-form-input trim placeholder="İsim" v-model="dataInfo.name" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12" md="6" lg="6">
                <validation-provider #default="validationContext" name="Organizasyon" rules="required">
                  <b-form-group label="* Organizasyon" :state="getValidationState(validationContext)">
                    <v-select v-model="dataInfo.organizationId" :options="organizationOptions" :reduce="val => val.value" :clearable="false"/>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12" md="6" lg="6">
                <validation-provider #default="validationContext" name="Durum" rules="required">
                  <b-form-group label="* Durum" label-for="isActive" :state="getValidationState(validationContext)">
                    <v-select v-model="dataInfo.isActive" :options="statusOptions" :reduce="val => val.value" :clearable="false"/>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

            </b-row>

            <div class="d-flex">
              <feather-icon icon="InfoIcon" size="16"/>
              <h5 class="mb-0 ml-50">Diğer Bilgiler</h5>
            </div>

            <b-row class="mt-1">

              <b-col cols="12" md="12" lg="12">
                <validation-provider #default="validationContext" name="Erişim Bilgileri">
                  <b-form-group label="Erişim Bilgileri" description="Bu erişim bilgilerine sahip kullanıcılar tarafından görüntülenecektir." :state="getValidationState(validationContext)">
                    <v-select v-model="dataInfo.accessDefinitionList" :options="accessDefinitionOptions" :reduce="val => val.value" :clearable="true" multiple="multiple"/>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12" md="12" lg="12">
                <validation-provider #default="validationContext" name="Açıklama">
                  <b-form-group label="Açıklama">
                    <b-form-textarea trim placeholder="Açıklama" v-model="dataInfo.description" :state="getValidationState(validationContext)" rows="3"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>


            </b-row>

          </b-col>

        </b-row>

        <action-buttons :back-route="'organization-position-list'"/>
      </b-form>
    </validation-observer>
    <Overlay :busy="busy"></Overlay>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BTabs, BTab, BFormTextarea, BAvatar, BFormCheckbox
} from 'bootstrap-vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import {onUnmounted, ref} from '@vue/composition-api'
import {useToast} from 'vue-toastification/composition'
import {toastMessage} from "@core/utils/utils";
import router from '@/router'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {required, email} from '@validations'
import {avatarText, getApiFile, statusOptions, yesNoOptions} from "@core/utils/filter"
import Overlay from "@/components/Overlay.vue";
import ActionButtons from "@/components/Form/ActionButtons.vue";
import vSelect from 'vue-select'
import store from "@/store"
import storeModule from "@/views/organization/position/store"
import organizationModule from "@/views/organization/organization/store"
import accessModule from "@/views/definition/access/store"

export default {
  components: {
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BTabs,
    BTab,
    BFormTextarea,
    BAvatar,

    ActionButtons,
    Overlay,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  props: {},
  data() {
    return {
      required,
      email,
    }
  },
  setup() {
    const STORE_MODULE_NAME = 'store'
    const STORE_ORGANIZATION_MODULE_NAME = 'store-organization'
    const STORE_ACCESS_MODULE_NAME = 'store-access'

    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, storeModule)
      store.registerModule(STORE_ORGANIZATION_MODULE_NAME, organizationModule)
      store.registerModule(STORE_ACCESS_MODULE_NAME, accessModule)
    }
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) {
        store.unregisterModule(STORE_MODULE_NAME)
        store.unregisterModule(STORE_ORGANIZATION_MODULE_NAME)
        store.unregisterModule(STORE_ACCESS_MODULE_NAME)
      }
    })

    const toast = useToast()
    const busy = ref(false)
    const dataInfo = ref({
      id: 0,
      organizationId: null,
      code: '',
      name: '',
      description: null,
      isActive: true,
      accessDefinitionList: []
    })

    const organizationOptions = ref([])
    const accessDefinitionOptions = ref([])

    const onSubmit = () => {
      busy.value = true
      store.dispatch('store/' + (router.currentRoute.params.id > 0 ? 'editItem' : 'addItem'), dataInfo.value).then(response => {
        toastMessage(toast, 'success', response.data.message)
        router.push({name: 'organization-position-list'})
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.message)
      }).finally(message => {
        busy.value = false
      })
    }

    busy.value = true
    store.dispatch('store-organization/fetchItems').then(response => {
      response.data.data.forEach((value, index) => {
        if (value.isActive) {
          organizationOptions.value.push({label: value.code + ' - ' + value.name, value: value.id})
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    busy.value = true
    store.dispatch('store-access/fetchItems').then(response => {
      response.data.data.forEach((value, index) => {
        if (value.isActive) {
          accessDefinitionOptions.value.push({label: value.name, value: value.id})
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    if (router.currentRoute.params.id > 0) {
      busy.value = true
      store.dispatch('store/fetchItem', {id: router.currentRoute.params.id}).then(response => {
        dataInfo.value = response.data.data
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.message)
        if (error.response.status === 403) {
          router.push({name: 'organization-position-list'})
        }
      }).finally(message => {
        busy.value = false
      })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    return {
      busy,
      dataInfo,
      refFormObserver,
      organizationOptions,
      accessDefinitionOptions,

      statusOptions,
      yesNoOptions,

      onSubmit,
      getValidationState,
      resetForm,

      getApiFile,
      avatarText,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>